import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  // Stimulus declarations
  static targets = ['list']
  static values = {
    autoscroll: Number,
  }

  // Typescript declarations
  listTarget!: HTMLElement
  autoscrollValue!: number

  // Instance variables
  observer: ResizeObserver;
  interval: NodeJS.Timeout;
  isFocused = false;
  targetIndex = 0;

  connect () {
    // Auto-detect list target if not specified
    if (!this.listTarget) {
      const listTarget = this.element.querySelector('ul, ol');
      if (listTarget)
        this.listTarget = listTarget as HTMLElement;
      else
        throw new Error('Missing target data-slider-target="list"');
    }

    // Show/hide buttons based on overflow
    this.observer = new ResizeObserver(() => this.checkOverflow());
    this.observer.observe(this.listTarget);

    // Autoscroll to next section
    if (this.autoscrollValue) {
      this.element.addEventListener('mouseenter', this.setFocusedTrue.bind(this));
      this.element.addEventListener('mouseleave', this.setFocusedFalse.bind(this));

      // Autoscroll
      this.interval = setInterval(() => !this.isFocused && this.go('next', true), this.autoscrollValue);
    }
  }

  disconnect () {
    this.observer.unobserve(this.listTarget);

    this.element.removeEventListener('mouseenter', this.setFocusedTrue.bind(this));
    this.element.removeEventListener('mouseleave', this.setFocusedFalse.bind(this));

    if (this.interval)
      clearInterval(this.interval);
  }

  // Used for mouseenter/mouseleave events
  setFocusedTrue () { this.isFocused = true }
  setFocusedFalse () { this.isFocused = false }

  // Used by buttons in template: data-action="click->slider#next"
  next () { this.go('next') }
  prev () { this.go('prev') }

  /** Show/hide buttons based on overflow */
  checkOverflow () {
    if (this.listTarget.scrollWidth === this.listTarget.offsetWidth)
      this.element.querySelectorAll('button').forEach((button) => { button.style.display = 'none' });
    else
      this.element.querySelectorAll('button').forEach((button) => { button.style.display = 'inherit' });
  }

  /**
   * Scroll slider to next/previous section
   * @param direction Direction
   * @param rotate Rotate to first/last element if already at end
   */
  go (direction: 'next' | 'prev', rotate = false) {
    const list = this.listTarget;
    const children = Array.from(list.children) as HTMLLIElement[];
    const lastIndex = children.length - 1;

    // Find bounds
    const leftX = list.scrollLeft;
    const rightX = list.scrollLeft + list.offsetWidth + 1; // +1 to account for rounding errors in layout

    // Find first/last visible element
    const firstVisible = children.findIndex(child => child.offsetLeft >= leftX);
    const remainingChildren = children.slice(firstVisible);
    const lastVisible = firstVisible + remainingChildren.length - remainingChildren
      .toReversed()
      .findIndex(child => child.offsetLeft + child.offsetWidth <= rightX) - 1;
    const numVisible = lastVisible - firstVisible + 1;

    // Save last target index
    const lastTargetIndex = this.targetIndex;

    // Last element is already visible
    if (direction === 'next')
      this.targetIndex = Math.min(lastIndex, lastVisible + 1);
    else if (direction === 'prev')
      this.targetIndex = Math.max(0, firstVisible - numVisible);

    // Rotate to first/last element
    if (this.targetIndex === lastTargetIndex && rotate)
      this.targetIndex = direction === 'next' ? 0 : lastIndex;

    // Scroll to target item
    list.scrollTo({ left: children[this.targetIndex].offsetLeft, behavior: 'smooth' });
  }
}
