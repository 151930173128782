import { TypedController } from '@sigbit/stimulus-ts';

export default class extends TypedController({
  targets: {
    input: HTMLSelectElement,
    list: HTMLInputElement,
    selectAll: HTMLInputElement,
  },
}) {
  connect () {
    this.onInputChange();
    this.inputTarget.addEventListener('change', this.onInputChange.bind(this));
    this.selectAllTarget.addEventListener('change', this.onSelectAllChange.bind(this));
  }

  disconnect () {
    this.inputTarget.removeEventListener('change', this.onInputChange.bind(this));
    this.selectAllTarget.removeEventListener('change', this.onSelectAllChange.bind(this));
  }

  onInputChange () {
    this.listTargets.forEach((input) => {
      const isActive = input.value === this.inputTarget.value;
      input.disabled = isActive;

      if (isActive)
        input.checked = true;
    });
  }

  onSelectAllChange () {
    this.listTargets.forEach((input) => {
      if (input.value !== this.inputTarget.value)
        input.checked = this.selectAllTarget.checked;
    });
  }
};
